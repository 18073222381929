"use client";

import { TypeGenericSection, TypeImageCollection } from "@contentful/types";
import { customImageLoader } from "@yardzen/next-client-util";
import { analytics } from "@yardzen/next-segment";
import { cn, Link, SegmentClickTypes } from "@yardzen/ui";
import Image from "next/image";
import { useRouter } from "next/navigation";

export function PressMentions({
  className,
  imageCollection,
  pressMentions,
  segmentFlow,
}: {
  className?: string;
  imageCollection: TypeImageCollection<"WITHOUT_UNRESOLVABLE_LINKS">;
  pressMentions: TypeGenericSection<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}) {
  const router = useRouter();

  return (
    <div className={cn("mx-auto w-fit px-8 py-12", className)}>
      <Link
        variant="nav"
        className="mx-auto max-w-3xl"
        href={pressMentions?.fields.CTAs?.[0]?.fields.url || "#"}
        onClick={async () => {
          const url = pressMentions?.fields.CTAs?.[0]?.fields.url;
          if (url) {
            await analytics.trackClicked({
              button_content: "Press Logos",
              flow_name: segmentFlow,
              button_name: "Home page press mentions",
              click_type: SegmentClickTypes.HYPERLINK,
            });
            router.push(url);
          }
        }}
      >
        <div className="grid grid-cols-3 gap-6 opacity-50 md:grid-cols-6">
          {imageCollection.fields.images?.map((image, index) => (
            <div key={index} className="flex items-center justify-center">
              <Image
                src={image?.fields?.image?.fields?.file?.url ?? ""}
                alt={image?.fields?.image?.fields?.title ?? ""}
                height={100}
                width={100}
                className="max-h-12 w-auto object-contain grayscale transition-all hover:grayscale-0"
                loading="lazy"
                loader={customImageLoader}
              />
            </div>
          ))}
        </div>
      </Link>
    </div>
  );
}
