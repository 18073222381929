"use client";

import { TypeHowItWorks, TypeHowItWorksStepSkeleton } from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customImageLoader } from "@yardzen/next-client-util";
import { analytics } from "@yardzen/next-segment";
import {
  Button,
  Card,
  CardContent,
  H3,
  H5,
  Link,
  P,
  SegmentClickTypes,
} from "@yardzen/ui";
import { Entry } from "contentful";
import Image from "next/image";
import { useRouter } from "next/navigation";
export function HowItWorks({
  howItWorks,
  segmentFlow,
}: {
  howItWorks: TypeHowItWorks<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}) {
  const router = useRouter();
  const url = howItWorks.fields.cta?.fields?.url;

  return (
    <section className="w-full px-8 py-16">
      <div className="mx-auto max-w-7xl">
        <div className="mb-12 text-center">
          <H3 className="font-display text-brand-secondary-dark text-4xl md:text-5xl">
            {howItWorks.fields.title}
          </H3>
        </div>

        <div className="grid gap-8 md:grid-cols-3">
          {howItWorks.fields?.steps?.map(
            (
              step:
                | Entry<
                    TypeHowItWorksStepSkeleton,
                    "WITHOUT_UNRESOLVABLE_LINKS",
                    string
                  >
                | undefined,
              index,
            ) => {
              if (!step) return null;
              return (
                <Card
                  key={index}
                  className="bg-brand-beige-lighter overflow-hidden border-none shadow-none"
                >
                  <CardContent className="justify-start p-0">
                    <div className="aspect-video w-full overflow-hidden">
                      <Link
                        variant="nav"
                        onClick={() => {
                          const url = howItWorks.fields.cta?.fields?.url;
                          if (!url) return;

                          analytics.trackClicked({
                            button_content: `How it works`,
                            flow_name: segmentFlow,
                            button_name: `How it works ${step.fields?.title}`,
                            click_type: SegmentClickTypes.HYPERLINK,
                          });
                          router.push(url);
                        }}
                      >
                        <Image
                          src={step.fields?.image?.fields?.file?.url ?? ""}
                          alt={step.fields?.title ?? ""}
                          className="h-full w-full object-cover"
                          height={300}
                          width={300}
                          loading="lazy"
                          loader={customImageLoader}
                        />
                      </Link>
                    </div>
                    <div className="justify-start py-6">
                      <H5 className="font-display text-brand-primary-dark mb-2 text-xl">
                        {step.fields?.title}
                      </H5>
                      <P className="text-brand-primary-dark/80">
                        {step.fields?.description}
                      </P>
                    </div>
                    <Button
                      variant="outline"
                      endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                      tracking={{
                        button_content: step.fields?.buttonText,
                        flow_name: segmentFlow,
                        button_name: `How it works - ${step.fields?.buttonText} - ${step.fields?.title}`,
                      }}
                      onClick={() => {
                        if (!url) return;
                        router.push(url);
                      }}
                    >
                      {step.fields?.buttonText}
                    </Button>
                  </CardContent>
                </Card>
              );
            },
          )}
        </div>
      </div>
    </section>
  );
}
