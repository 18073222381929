"use client";

import { TypeImageCollection } from "@contentful/types";
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  H1,
  ImageWithAvatarCard,
  P,
} from "@yardzen/ui";

export function ImageCardsWithDetails({
  imageCollection,
  segmentFlow,
}: {
  imageCollection: TypeImageCollection<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}) {
  const { title, description, images } = imageCollection.fields;
  const allImages =
    images.length < 6 ? [...images, ...images, ...images] : images;

  return (
    <div
      id={imageCollection.fields.anchorLinkId || imageCollection.sys.id}
      className="bg-brand-beige-light flex flex-col items-center overflow-hidden pb-12 text-center"
    >
      <H1 className="text-brand-secondary-dark px-12 pt-12 md:px-4">{title}</H1>
      <P className="text-brand-moss-greens-90 w-[90%] px-12 pb-8 text-base md:min-w-[37%] md:max-w-[65%] md:px-4 md:text-xl">
        {description}
      </P>
      <Carousel
        className="px-4"
        opts={{
          loop: true,
        }}
      >
        <CarouselContent aria-live="off" className="pb-6">
          {allImages?.map((image) => {
            const imageUrl = image?.fields?.image?.fields?.file?.url || "";
            const alt =
              image?.fields?.image?.fields?.description ||
              `Yardzen yard in ${image?.fields?.image?.fields?.title}`;
            const avatarUrl = image?.fields?.avatar?.fields?.file?.url || "";
            const avatarAlt =
              image?.fields?.avatar?.fields?.description ||
              `Yardzen yard for ${image?.fields?.avatar?.fields?.title}`;
            return (
              <CarouselItem
                key={image?.sys.id}
                className="basis-1/6 sm:basis-1/4"
              >
                <ImageWithAvatarCard
                  imageUrl={imageUrl}
                  imageAlt={alt}
                  avatarUrl={avatarUrl}
                  avatarAlt={avatarAlt}
                  cardHeading={image?.fields?.image?.fields?.title}
                  cardSubheading={image?.fields?.avatar?.fields?.title}
                  linkUrl={image?.fields?.link}
                  linkText={image?.fields?.icon?.toLocaleUpperCase()}
                  tracking={{
                    button_content: image?.fields?.icon?.toLocaleUpperCase(),
                    flow_name: segmentFlow,
                    button_name:
                      "Yardzen Yard - " + image?.fields?.image?.fields?.title,
                  }}
                />
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <CarouselDots
          count={images?.length || 0}
          dotClassName="border-brand-secondary-dark h-2.5 w-2.5 rounded-full"
          selectedDotClassName="border-brand-secondary-dark bg-brand-secondary-dark h-2.5 w-2.5 rounded-full"
          tracking={{
            button_content: "Yardzen yards Carousel dots",
            flow_name: segmentFlow,
            button_name: "Yardzen yards Carousel dots",
          }}
        />
      </Carousel>
    </div>
  );
}
